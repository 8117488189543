<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
      <fieldset :disabled="loading">
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="name" name="Código" rules="required">
              <v-text-field
                v-model="fields.name"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.code') + '*'"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="display_name" name="Nome" rules="required">
              <v-text-field
                v-model="fields.display_name"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.name') + '*'"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" vid="permissions" name="Permissões" rules="required">
              <multiple-select
                v-model="fields.permissions"
                :items="permissions"
                item-text="display_name"
                item-value="id"
                menu-props="auto"
                :label="$t('globals.permissions') + '*'"
                prepend-inner-icon="mdi-lock"
                :error-messages="errors"
                return-object
              ></multiple-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" vid="users" name="Utilizadores">
              <multiple-select
                v-model="fields.users"
                :items="users"
                :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                item-value="id"
                menu-props="auto"
                :label="$t('globals.users') + '*'"
                prepend-inner-icon="mdi-account-multiple"
                :error-messages="errors"
                return-object
              ></multiple-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-6" align="center" justify="space-around">
          <v-btn :disabled="invalid" depressed color="primary" type="submit">
            {{$t('globals.save')}}
          </v-btn>
        </v-row>
      </fieldset>
    </v-form>
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
      {{$t('role.success')}}
    </DialogSuccess>
  </validation-observer>
</template>
<script>
import User from "@/api/User.js";
import Permission from "@/api/Permission.js";
import Role from "@/api/Role.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
  components: {
    MultipleSelect,
    DialogSuccess
  },
  props: {
   role: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    role: {
      immediate: true, 
      handler (val) {
        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;

        this.fields.id = data.id;
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    fields: {
      id: null,
      name: null,
      display_name:null,
      users:[],
      permissions: [],
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    users: [],
    permissions: [],
    success: false,
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/settings/roles');
    },
    fillBaseData(){
      User.list({is_active: 1}).then(({data}) => {
        this.users = data;
      });

      Permission.list({role_id: this.id}).then(({data}) => {
        this.permissions = data;
      });
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        let data = {...this.fields};

        data.permissions = data.permissions.map(i => i.id);

        data.users = data.users.map(i => i.id);

        Role[this.method](data, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
