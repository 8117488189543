import BaseApi from "./BaseApi";

export default class Severities extends BaseApi{
    construct() {}

    static async list(params) {
        let url = Severities.getApiUrl() + "list";

        return Severities.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/severities/";
    }
}