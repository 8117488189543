<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Edição de Utilizador</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <user v-else method="update" :user="user"></user>
      </div>
  </section>
</template>
<script>
import User from '@/components/users/User'
import UserApi from "@/api/User.js";

export default {
  components: {
    User
  },
  beforeCreate(){
    document.title = this.$t('app_name')
  },
  data: () => ({
      user: {},
      loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "users.update"]) && this.$root.session.id != this.$route.params.id) {
      this.$router.push('/admin');
    }

    UserApi.find(this.$route.params.id).then(({data}) => { this.user = data; this.loading = false;});
  },
  methods:{
    
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.users_management'),
          disabled: false,
          to: '/admin/settings/users',
          exact: true,
        },
        {
          text: this.$t('user.edit_crumb'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
