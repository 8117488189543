<template>
  <v-app>
    <div class="bg_login"></div>
    <div class="absolute">      
      <v-img
          class="d-none d-sm-flex ml-12 mt-12"
          lazy-src="@/assets/fundo_conduril.png"
          src="@/assets/fundo_conduril.png"
          max-width="350"
      ></v-img> 
      
      <v-img
          class="d-sm-none mt-6 ml-6 mb-n10"
          lazy-src="@/assets/fundo_conduril.png"
          src="@/assets/fundo_conduril.png"
          max-width="350"
      ></v-img>
    </div>
    <v-main>
      <v-container fill-height>
        <!-- DESKTOP VERSION -->        
        <v-row class="d-none d-sm-flex">
          <v-row class="div_login">
            <v-col cols="12" md="6">
              <div class="text-right mt-12">
                <p class="orange-app text-h3 font-weight-black">Área reservada</p>
                <p class="text-h6 font-weight-light">Portal de Denúcias</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <validation-observer
                  ref="login"
                  v-slot="{ invalid, dirty }"
                >
                  <v-form @submit.prevent="submit">
                    <v-row>
                      <v-col cols="12">
                        <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="username" label="Email\Username" prepend-inner-icon="mdi-account" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-btn
                          depressed
                          color="primary"
                          class="mr-4 text-white"
                          type="submit"
                          :disabled="invalid && !dirty"
                          large
                          block
                        >
                          Login
                        </v-btn>
                      </v-col>
                        <v-col cols="12">


                        <v-btn
                          text
                          color="primary"
                          class="mt-4"
                          type="button"
                          block
                          @click="reset_password.opened = !reset_password.opened"
                        >
                          Esqueci-me da password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <!-- DESKTOP VERSION END-->

        <!-- MOBILE VERSION -->
        <v-row class="d-sm-none">
          <v-row class="div_login_mobile">
            <v-col cols="12" md="6">
              <div class="text-center mt-12">
                <p class="orange-app text-h3 font-weight-black">Área reservada</p>
                <p class="text-h6 font-weight-light">Acompanhamento de projetos</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <validation-observer
                  ref="login"
                  v-slot="{ invalid, dirty }"
                >
                  <v-form @submit.prevent="submit">
                    <v-row>
                      <v-col>
                        <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="username" label="Email\Username" prepend-inner-icon="mdi-account" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          depressed
                          color="primary"
                          class="mr-4 text-white"
                          type="submit"
                          :disabled="invalid && !dirty"
                          large
                          block
                        >
                          Login
                        </v-btn>
                        </v-col>
                        <v-col>

                        <v-btn
                          text
                          color="primary"
                          class="mt-4"
                          type="button"
                          block
                          @click="reset_password.opened = !reset_password.opened"
                        >
                          Esqueci-me da password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <!-- MOBILE VERSION END -->
        </v-container>
    </v-main>
    <v-dialog
        v-model="reset_password.opened"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5 orange-app">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-if="!reset_password.reseted" ref="reset" v-slot="{ invalid }">
              <v-form @submit.prevent="reset">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                        <v-text-field
                          v-model="reset_password.email"
                          prepend-inner-icon="mdi-at"
                          label="Email"
                          type="email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="#F86F15" class="mr-5 ml-5 mb-5 text-white" type="submit">
                        Enviar email
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-container v-else>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#F86F15"
              text
              @click="reset_password.opened = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-app>
</template>
<style>
 .text-white input {
    color: white !important;
  }
.text-white label {
  color: white !important;
}
.text-white i {
  color: white !important;
}
.text-white v-messages__wrapper{
  color: white !important;
}
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    /*background: url( '../assets/imagem_login.jpg') no-repeat center center;*/
    background-size: cover;
    background-color: red;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    transform: scale(1.1);
  }
  .div_login{
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.div_login_mobile{
  opacity: 0.8;
}
.bg_login {
    width: 100%;
    height: 100%;
    position: absolute;
    background: url( '../assets/dreamdeal1.png') no-repeat center center;
    background-size: cover;
  }
  </style>
<script>
import User from "@/api/User.js"

export default {
  components: {
  },
  data: () => ({
    username: "",
    password: "",
    reset_password: {
      opened: false,
      email: "",
      reseted: false,
    }
  }),
  beforeCreate(){
    document.title = "Incognito";

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }

  },
  mounted(){
    
  },
  methods:{
    submit () {
        this.$refs.login.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.logout()
            .then(() => {
              this.login();
            }).catch(() => {
              this.login();
            })
        });
    },
    login(){
      let pwd = this.$encryptValue(this.password)
      
      User.login(this.username, pwd)
        .then(({data}) => {

            if(data.two_factor){
              this.$router.push('/2fa');

              return;
            }

            localStorage['auth'] = 1;
            
            this.$router.push('/admin');
        }).catch(err => {

          if(err.response.status == 422) {

            this.$refs.login.setErrors(err.response.data.errors);
            
            return;
          }
      });
    },
    reset () {
      this.$refs.reset.validate().then((valid) => {
        if (!valid) {
          return;
        }

        User.reset(this.reset_password.email)
          .then(() => {

              this.reset_password.reseted = true;
          }).catch(err => {

            if(err.response.status == 422) {

              this.$refs.reset.setErrors(err.response.data.errors);
              
              return;
            }

            this.reset_password.reseted = false;

        });
      });
    },   
  },     
};
</script>
