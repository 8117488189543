import BaseApi from "./BaseApi";

export default class Exports extends BaseApi{
    construct() {}

    static async export(params) {
        let url = Exports.getApiUrl() + "exports";

        return Exports.getAxiosInstance().get(url,{params: params});
    }

    static async delete(id) {
        return await Exports.getAxiosInstance().delete(Exports.getApiUrl() + 'file/' + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/exports/";
    }
}