<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-shield-account</v-icon> <span>{{$t('category.add_crumb')}}</span>
        </h1>
  
        <div class="mt-16">
          <category method="create"></category>
        </div>
    </section>
  </template>
  <script>
  import Category from '@/components/complaints/categories/Category'
  
  export default {
    components: {
        Category
    },
    beforeCreate(){
      document.title = this.$t('app_name')
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "categories.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.categories_management'),
            disabled: false,
            to: '/admin/settings/categories',
            exact: true,
          },
          {
            text: this.$t('category.add_crumb'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  