import BaseApi from "./BaseApi";

export default class Complaints extends BaseApi{
    construct() {}

    static async getIntroduction() {
        return await Complaints.getAxiosInstance().get(Complaints.getApiUrl() + 'introduction');
    }

    static async search(params) {
        return await Complaints.getAxiosInstance().get(Complaints.getApiUrl(),{params: params});
    }

    static async update(params, id) {
        return await Complaints.getAxiosInstance().put(Complaints.getApiUrl() + id,params);
    }

    static async find(id) {
        return await Complaints.getAxiosInstance().get(Complaints.getApiUrl() + id);
    }

    static async getRelations(params) {
        let url = Complaints.getApiUrl() + "relations";

        return Complaints.getAxiosInstance().get(url,{params: params});
    }

    static async setIntroduction(params, id) {
        return await Complaints.getAxiosInstance().put(Complaints.getApiUrl() + 'introduction/' + id ,params);
    }

    static async list(params) {
        let url = Complaints.getApiUrl() + "list";

        return Complaints.getAxiosInstance().get(url,{params: params});
    }

    static async downloadFile(file, id) {
        let url = Complaints.getApiUrl() + 'download/' + file + '/' + id
        
        return await Complaints.getAxiosInstanceDownload(url)
    }

    static async export(id, params) {
        let url = Complaints.getApiUrl() + "exports/downloads/" + id;

        return Complaints.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/complaints/";
    }
}