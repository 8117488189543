<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-pencil</v-icon> <span>{{$t('menu.define_complaint_template')}}</span>
        </h1>
  
        <div class="mt-16" >
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
          <Introduction v-if="!loading" :fields="fields" @reload="fillBaseData"/>
        </div>
    </section>
  </template>
  <script>
  import Complaints from '@/api/Complaints.js'
  import Introduction from '@/components/complaints/introduction/Introduction.vue'

  export default {
    components: {
      Introduction
    },
    beforeCreate(){
      document.title = this.$t('app_name')
    },
    data(){
      return{
        loading: true,
        fields: null
      }
    },
    mounted(){  
      if(!this.$root.session.hasPermission(["super", "complaints_introduction.create"])) {
        this.$router.push('/admin');
      }

      this.fillBaseData()  
    },
    methods:{
        async fillBaseData(){
          this.loading = true
            await Complaints.getIntroduction().then(({data}) => {
                this.fields = data
            })

          this.loading = false
        }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.complaints_management'),
            disabled: true,
            exact: true,
          },
          {
            text: this.$t('menu.define_complaint_template'),
            disabled: false,
            to: '/admin/settings/states',
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  