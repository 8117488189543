import BaseApi from "./BaseApi";

export default class Notification extends BaseApi{
    construct() {}

    static async list(unread, offset = 0, tenant = null) {
        let params = {
            unread: unread,
            offset: offset
        }
        return await Notification.getAxiosInstance().get(Notification.getApiUrl(tenant),{params: params});
    }

    static async readAll() {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getApiUrl());

        return response;
    }

    static async read(id) {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getApiUrl() + id);

        return response;
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/notifications/";
    }

}