import BaseApi from "./BaseApi";

export default class State extends BaseApi{
    construct() {}

    static async search(params) {
        return await State.getAxiosInstance().get(State.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await State.getAxiosInstance().post(State.getApiUrl(),params);
    }

    static async update(params, id) {
        return await State.getAxiosInstance().put(State.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await State.getAxiosInstance().delete(State.getApiUrl() + id);
    }

    static async find(id) {
        return await State.getAxiosInstance().get(State.getApiUrl() + id);
    }

    static async list(params) {
        let url = State.getApiUrl() + "list";

        return State.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/states/";
    }
}