<template>
    <validation-observer ref="form" v-slot="{ invalid }" v-if="!loading">
        <v-form @submit.prevent="submit"  class="mb-16">
            <v-tabs v-model="tab" background-color="primary" dark centered fixed-tabs>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab v-for="language in languages" :key="language.id">{{language.language}}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="language in languages" :key="language.language">
                    <v-row class="mt-8">
                        <v-col cols="12">
                            <v-text-field
                                v-model="fields.i18n[language.language].title"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.title')+ ' *'"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-tiptap v-model="fields.i18n[language.language].description" />
                        </v-col>
                    </v-row>                    
                </v-tab-item>
            </v-tabs-items>
            <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    {{$t('globals.save')}}
                </v-btn>
          </v-row>
        </v-form>
    </validation-observer>
</template>
<script>
import Complaints from '@/api/Complaints.js'
  import Languages from "@/api/Languages.js"

export default{
    props:{
        fields:{
            typeof: Object, 
            default: {
                id: null,
                description: null
            }
        }
    },
    data(){
      return{
        tab: 0,
        success: false,
        loading: true,
        languages:[],
      }
    },
    mounted(){
        this.loading = true
        this.fillBaseData()
    },
    methods:{
        async fillBaseData(){
            await Languages.list().then(({data}) => {
                this.languages = data;
            });
            this.loading = false
        },
        submit(){
            this.$refs.form.validate().then((result) => {
                this.loading = true;
                
                if(!result){
                    this.loading = false;
                    return;
                }
        
                let data = {...this.fields};
        
                Complaints['setIntroduction'](data, this.fields.id).then(() => {
        
                    this.success = true;
        
                    this.loading = false;

                    this.$emit('reload')
                    
                }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
                    });
                })
        }
    }
}
</script>