<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-shield-account</v-icon> <span>{{$t('role.add_crumb')}}</span>
      </h1>

      <div class="mt-16">
        <role method="create"></role>
      </div>
  </section>
</template>
<script>
import Role from '@/components/roles/Role'

export default {
  components: {
    Role
  },
  beforeCreate(){
    document.title = this.$t('app_name')
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "roles.create"])) {
      this.$router.push('/admin');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.definitions'),
          disabled: false,
          to: '/admin/settings/departments',
          exact: true,
        },
        {
          text: this.$t('role.add_crumb'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
