import BaseApi from "./BaseApi";

export default class Languages extends BaseApi{
    construct() {}

    static async list(params) {
        let url = Languages.getApiUrl() + "list";

        return Languages.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/languages/";
    }
}