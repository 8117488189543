<template>
  <section class="mt-16">
    <v-breadcrumbs
      large
      :items="[]"
      divider="/"
      class="pl-0"
    ></v-breadcrumbs>

    <h1 class="primary--text">
      <v-icon color="primary">mdi-view-dashboard</v-icon> <span>{{$t('dashboard.title')}}</span>
    </h1>
    <v-row class="mt-4" v-if="!loading">
      <v-col cols="12" md="3">
        <Card :title="$t('dashboard.mine')" :addCircular="true" :subTitle="fields.total_my_complaints" :description="fields.total_my_complaints + '/' + fields.total_complaints" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" md="3">
        <Card :title="$t('dashboard.total')" :subTitle="fields.total_complaints" :description="$t('globals.complaints')" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" md="3">
        <Card :title="$t('dashboard.new')" :subTitle="fields.total_new_complaints" :description="$t('globals.complaints')" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" md="3">
        <Card title="Abertas" :subTitle="fields.total_open_cases" :description="$t('globals.complaints')" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" class="text-h6 primary white--text mt-4">
        {{$t('dashboard.statistics')}}
      </v-col>
      <v-col cols="12" md="6" v-if="fields.category_complaints.values.length > 0">
        <BasicPieChart :values="fields.category_complaints.values"/>
      </v-col>
      <v-col cols="12" md="6" v-if="fields.department_complaints.values.length > 0">
        <BasicPieChart :values="fields.department_complaints.values"/>
      </v-col>
      <v-col cols="12" class="text-h6 primary white--text mt-4">
        {{$t('dashboard.monthly')}}
      </v-col>
      <v-col cols="12">
        <BasicAreaChart :legend_series="$t('dashboard.monthly')" :categories="fields.month_complaints.months" :new_complaints="fields.month_complaints.values"/>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import Card from '@/components/ui/Card'
import Dashboard from '@/api/Dashboard.js'
import BasicAreaChart from '@/components/ui/graphs/BasicAreaChart.vue'
import BasicPieChart from '@/components/ui/graphs/BasicPieChart.vue'

export default{
  name: 'Dashboard',
  components:{
    Card,
    BasicAreaChart,
    BasicPieChart
  },
  mounted(){
    this.fillBaseData()
  },
  data(){
    return{
      loading: true,
      complaints: [],
      fields: null,
      categories: ["05", "06", "07", "08", "09", "10", "11"]
    }
  },
  methods:{
    async fillBaseData(){
      await Dashboard.getStatistics().then(({data}) => {
          this.fields = data
      });

      this.loading = false
    }
  }

}
</script>