<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mudar estado da denúncia?
        </v-card-title>

        <v-card-text>
          Antes de prosseguir gostaria de mudar o estado da denúncia?
            <v-select
                v-model="stateToChange"
                :items="states"
                :item-text="item => item.i18n[$i18n.locale]"
                item-value="id"
                menu-props="auto"
                label="Estado"
                prepend-inner-icon="mdi-account"
                clearable
            ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary white--text"
            @click="$emit('add-comment', stateToChange)"
          >
            Prosseguir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default{
    name: "ChangeState",
    props:{
        actualState: String,
        states: Array
    },
    data(){
        return{
            dialog: false,
            loading: true,
            stateToChange: ''
        }
    },
    mounted(){
        this.dialog = true
        this.stateToChange = this.actualState
        this.loading = false
    }
}
</script>