import BaseApi from "./BaseApi";

export default class Comment extends BaseApi{
    construct() {}

    static async create(params) {
        return await Comment.getAxiosInstance().post(Comment.getApiUrl(),params);
    }   

    static async downloadFile(file, id) {
        let url = Comment.getApiUrl() + 'download/' + file + '/' + id
        
        return await Comment.getAxiosInstanceDownload(url)
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/comment/";
    }
}