import BaseApi from "./BaseApi";

export default class Configuration extends BaseApi{
    construct() {}

    static async list(params) {
        let url = Configuration.getApiUrl() + "list";

        return Configuration.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/configurations/";
    }
}