var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"bg_login"}),_c('div',{staticClass:"absolute"},[_c('v-img',{staticClass:"d-none d-sm-flex ml-12 mt-12",attrs:{"lazy-src":require("@/assets/fundo_conduril.png"),"src":require("@/assets/fundo_conduril.png"),"max-width":"350"}}),_c('v-img',{staticClass:"d-sm-none mt-6 ml-6 mb-n10",attrs:{"lazy-src":require("@/assets/fundo_conduril.png"),"src":require("@/assets/fundo_conduril.png"),"max-width":"350"}})],1),_c('v-main',[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"d-none d-sm-flex"},[_c('v-row',{staticClass:"div_login"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-right mt-12"},[_c('p',{staticClass:"orange-app text-h3 font-weight-black"},[_vm._v("Área reservada")]),_c('p',{staticClass:"text-h6 font-weight-light"},[_vm._v("Portal de Denúcias")])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"login",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Email\\Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email\\Username","prepend-inner-icon":"mdi-account","error-messages":errors},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Password","prepend-inner-icon":"mdi-lock","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mr-4 text-white",attrs:{"depressed":"","color":"primary","type":"submit","disabled":invalid && !dirty,"large":"","block":""}},[_vm._v(" Login ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"primary","type":"button","block":""},on:{"click":function($event){_vm.reset_password.opened = !_vm.reset_password.opened}}},[_vm._v(" Esqueci-me da password ")])],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-row',{staticClass:"d-sm-none"},[_c('v-row',{staticClass:"div_login_mobile"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center mt-12"},[_c('p',{staticClass:"orange-app text-h3 font-weight-black"},[_vm._v("Área reservada")]),_c('p',{staticClass:"text-h6 font-weight-light"},[_vm._v("Acompanhamento de projetos")])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"login",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Email\\Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email\\Username","prepend-inner-icon":"mdi-account","error-messages":errors},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Password","prepend-inner-icon":"mdi-lock","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4 text-white",attrs:{"depressed":"","color":"primary","type":"submit","disabled":invalid && !dirty,"large":"","block":""}},[_vm._v(" Login ")])],1),_c('v-col',[_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"primary","type":"button","block":""},on:{"click":function($event){_vm.reset_password.opened = !_vm.reset_password.opened}}},[_vm._v(" Esqueci-me da password ")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.reset_password.opened),callback:function ($$v) {_vm.$set(_vm.reset_password, "opened", $$v)},expression:"reset_password.opened"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"h5 orange-app"},[_vm._v("Reset Password")])]),_c('v-card-text',[(!_vm.reset_password.reseted)?_c('validation-observer',{ref:"reset",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-at","label":"Email","type":"email","clearable":"","error-messages":errors},model:{value:(_vm.reset_password.email),callback:function ($$v) {_vm.$set(_vm.reset_password, "email", $$v)},expression:"reset_password.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5 text-white",attrs:{"disabled":invalid,"depressed":"","color":"#F86F15","type":"submit"}},[_vm._v(" Enviar email ")])],1)],1)],1)],1)]}}],null,false,1522659687)}):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('h2',{staticClass:"success--text text-center"},[_vm._v("Email enviado!")]),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-icon',{staticClass:"text-center",attrs:{"large":"","color":"success"}},[_vm._v(" mdi-checkbox-marked-circle-outline ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#F86F15","text":""},on:{"click":function($event){_vm.reset_password.opened = false}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }