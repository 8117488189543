<template>
    <div id="chart" v-if="!loading">
        <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default{
    name:"BasicAreaChart",
    components:{
        'apexchart': VueApexCharts
    },
    props:{
        legend_series: String,
        categories: Array,
        new_complaints: Array
    },
    data(){
        return{
            loading: true,
            
            
        }
    },
    mounted(){
        this.loading = false
    },
    computed:{
        series(){
            return [{
            name: this.legend_series,
            data: this.new_complaints
            }]
        },
        chartOptions(){
            return {
                chart: {
                    height: 350,
                    type: 'area',
                    zoom:{
                        enabled: false
                    }
                },
                    dataLabels: {
                    enabled: false
                },
                    stroke: {
                    curve: 'smooth'
                },
                    xaxis: {
                    type: 'datetime',
                    categories: this.categories
                },
                    tooltip: {
                    x: {
                        format: 'MM/yy'
                    },
                },
            }
            
        }
    }
}
</script>