<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="6">
                <v-select
                    v-model="fields.export_type"
                    :items="$t('export.types')"
                    :item-text="item => item.name"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('globals.file')"
                    prepend-inner-icon="mdi-account"
                    clearable
                ></v-select>  
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    v-model="fields.type"
                    :items="types"
                    menu-props="auto"
                    :label="$t('globals.type')"
                    prepend-inner-icon="mdi-account"
                    clearable
                ></v-select>  
            </v-col>
            <v-col cols="12" md="3">
                <v-menu
                    v-model="pickerInicio"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <validation-provider v-slot="{ errors }" vid="Inicio" :name="$t('globals.start_date')" rules="required">
                        <v-text-field
                            v-model="fields.start"
                            :label="$t('globals.start_date')"
                            :error-messages="errors"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </validation-provider>
                    </template>
                    <v-date-picker
                    v-model="fields.start"
                    :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                    @input="pickerInicio = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="3">
                <v-menu
                    v-model="pickerFim"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <validation-provider v-slot="{ errors }" vid="Fim" :name="$t('globals.end_date')" rules="required">
                        <v-text-field
                            v-model="fields.end"
                            :label="$t('globals.end_date')"
                            :error-messages="errors"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </validation-provider>
                    </template>
                    <v-date-picker
                    v-model="fields.end"
                    :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                    @input="pickerFim= false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="4">
                <v-btn @click="submit">{{$t('globals.save')}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Exports from '@/api/Exports.js'

export default{
    name: "Export",
    data(){
        return{
            all_days_available: false,
            pickerInicio: false,
            pickerFim: false,
            types: ['PDF', 'Excel'],
            fields:{
                export_type: null,
                start: null,
                end: null,
                locale: null,
                type: 'PDF'
            }
        }
    },
    methods:{
        submit(){
            this.fields.locale = this.$i18n.locale
            Exports.export(this.fields).then((resp) => {
                if(resp.data != null){
                    window.open(resp.data, '_blank')
                    if(this.fields.type == 'Excel'){
                        Exports.delete(resp.data.path)
                        .then(() => {
                            
                        });
                    }
                }
            });
        }
    }
}
</script>