<template>
    <div id="chart" v-if="!loading">
        <apexchart type="pie" width="480" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default{
    name:"BasicAreaChart",
    components:{
        'apexchart': VueApexCharts
    },
    props:{
        values: Array,
    },
    data(){
        return{
            loading: true           
        }
    },
    mounted(){
        this.loading = false
    },
    computed:{
        series(){
            let aux = []
            this.values.forEach(element => {
                aux.push(element.counter)
            });
            return aux
        },
        labels(){
            let returner = []
            this.values.forEach(element => {
                returner.push(element.object.i18n[this.$i18n.locale])
            });
            return returner
        },
        chartOptions(){
            return {              
                chart: {
                    width: 480,
                    type: 'pie',
                },
                labels: this.labels,
                legend:{
                    position: 'bottom'
                },
                responsive: [{
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom',
                        }
                    }
                }]
            }
            
        }
    }
}
</script>