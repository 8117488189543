import BaseApi from "./BaseApi";

export default class Dashboard extends BaseApi{
    construct() {}

    static async getStatistics() {
        return await Dashboard.getAxiosInstance().get(Dashboard.getApiUrl());
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/dashboard/";
    }
}