import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import NotFound from '../views/NotFound.vue'
import Roles from '../views/settings/roles/Roles.vue'
import CreateRole from '../views/settings/roles/CreateRole.vue'
import UpdateRole from '../views/settings/roles/UpdateRole.vue'
import Users from '../views/settings/users/Users.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'
import Layout from '../Layout.vue'
import Logs from '../views/logs/Manage.vue'

import Departments from '../views/settings/departments/Departments.vue'
import UpdateDepartment from '../views/settings/departments/UpdateDepartment.vue'
import CreateDepartment from '../views/settings/departments/CreateDepartment.vue'
import Categories from '../views/complaints/categories/Categories.vue'
import UpdateCategory from '../views/complaints/categories/UpdateCategory.vue'
import CreateCategory from '../views/complaints/categories/CreateCategory.vue'
import States from '../views/complaints/states/States.vue'
import UpdateState from '../views/complaints/states/UpdateState.vue'
import CreateState from '../views/complaints/states/CreateState.vue'

import ComplaintIntroduction from '../views/complaints/introduction/ComplaintIntroduction.vue'

import Complaints from '../views/complaints/Complaints.vue'
import UpdateComplaint from '../views/complaints/UpdateComplaint.vue'

import Export from '../views/exports/Exports.vue'

import Notifications from '../views/Notifications.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/admin',
                component: Dashboard
            },
            {
                path: '/admin/notifications',
                component: Notifications
            },
            {
                path: '/admin/exports',
                component: Export
            },
            {
                path: '/admin/settings/roles',
                component: Roles,
            },
            {
                path: '/admin/settings/roles/create',
                component: CreateRole,
            },
            {
                path: '/admin/settings/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/admin/settings/users',
                component: Users
            },
            {
                path: '/admin/settings/my-user/:id',
                component: UpdateUser
            },
            {
                path: '/admin/settings/logs',
                component: Logs
            },
            {
                path: '/admin/settings/users/create',
                component: CreateUser,
            },
            {
                path: '/admin/settings/users/:id',
                component: UpdateUser,
            },
            {
                path: '/admin/settings/departments',
                component: Departments,
            },
            {
                path: '/admin/settings/departments/create',
                component: CreateDepartment,
            },
            {
                path: '/admin/settings/departments/:id',
                component: UpdateDepartment,
            },
            {
                path: '/admin/complaints/categories',
                component: Categories,
            },
            {
                path: '/admin/complaints/categories/create',
                component: CreateCategory,
            },
            {
                path: '/admin/complaints/categories/:id',
                component: UpdateCategory,
            },
            {
                path: '/admin/complaints/states',
                component: States,
            },
            {
                path: '/admin/complaints/states/create',
                component: CreateState,
            },
            {
                path: '/admin/complaints/states/:id',
                component: UpdateState,
            },
            {
                path: '/admin/complaints/introduction',
                component: ComplaintIntroduction,
            },
            {
                path: '/admin/complaints/list',
                component: Complaints,
            },
            {
                path: '/admin/complaints/:id',
                component: UpdateComplaint,
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
